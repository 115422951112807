<template>
  <div>
    <div class="border">
      <div class="typeTitle">开放时间</div>
      <div>{{details.BusinessTime}}</div>
    </div>
    <div class="border">
      <div class="typeTitle">联系电话</div>
      <div style="color:#666666;">联系电话： <span style="color:#31D984;">{{details.TouchTel}}</span> </div>
    </div>
    <div class="border">
      <div class="jieshao">景区介绍</div>
      <div class="Introduce">{{details.Introduce}}</div>
      <div style="margin:10px 0;"
        v-if="imgsList.length != '0'">
        <div v-for="item in imgsList"
          :key="item">
          <img :src="item"
            style="width:100%;"
            alt="">
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { WeGetScenicSpotPageBySSId } from "@/api/jingqu";
export default {
  data () {
    return {
      details: {},
      imgsList: [],
    }
  },
  created () {
    this.getScenicSpotPageBySSId()
  },
  methods: {
    getScenicSpotPageBySSId () {
      console.log(this.$route.params.Id);
      WeGetScenicSpotPageBySSId({ SSId: this.$route.params.Id }).then((res) => {
        this.details = res.data.data
        if (res.data.dataImgUrls != '') {
          this.imgsList = res.data.data.ImgUrls.split(",")
        }
      })
    },
  },
}
</script>

<style scoped>
.border {
  padding: 10px;
  border-top: 15px solid #f9f9f9;
  line-height: 30px;
}
.typeTitle {
  color: #0c0c0c;
  font-size: 18px;
  font-weight: bold;
}
.Introduce {
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-line;
  color: #0c0c0c;
}
.jieshao {
  color: #0c0c0c;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
</style>